.MainboxCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.MainboxRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Shadow {}

.Cardtitile {
    color: black !important;
    font-size: 1rem !important;
}

.BlogTite {
    font-size: 1.3rem !important;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
}

.BlogImge {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.Flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center
}

.Indas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.css-pc76bz {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.css-qrax4f {
    border: 0;
    max-width: 229px;

    border-radius: 10px;
    width: 100%;
    cursor: pointer;
    background-color: white;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.css-sb08dx {
    padding: 0.25rem;
    text-align: center;
}

.sapn1 {

    box-sizing: border-box;
    display: inline-block;
    overflow: hidden;
    width: initial;
    height: initial;
    background: none;
    opacity: 1;
    border: 0px;
    margin: 0px;
    padding: 0px;
    position: relative;
    max-width: 100%;

}

.span2 {
    box-sizing: border-box;
    display: block;
    width: initial;
    height: initial;
    background: none;
    opacity: 1;
    border: 0px;
    margin: 0px;
    padding: 0px;
    max-width: 100%;

}

.Image {
    border-radius: 10px;
    object-fit: cover;

    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    height: 229px;
    width: 229px;
    /* min-height: 100%;
    max-height: 100%; */

}

.css-1dm2hj8 {
    padding: 0.7rem;


    text-align: center;
}

.Inda {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.Timer {
    padding: 5px;
    border: 4px solid white;
    border-radius: 51%;
    height: 50px;
    width: 50px;

}

.container {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.container p {
    margin: 5px;
    padding: 0px;
    color: rgb(17 69 60);
    font-weight: 700;
    font-size: 19px;
}

.NEWBOX {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -20px;

}




.Reimge {
    height: 100px;
    width: 100px;
}

.REbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.MainboxCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.MainboxRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Shadow {}

.Flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center
}

.css-pc76bzs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.css-qrax4fs {
    max-width: 160px;
    height: 204px;
    border-radius: 10px;
    width: 100%;
    cursor: pointer;
    background-color: white;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.css-sb08dxs {
    height: 100%;
    padding: 0.25rem;
    text-align: center;
}

.sapn1s {

    display: flex;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
    width: initial;
    flex-direction: column;
    /* height: initial; */
    justify-content: space-around;
    background: none;
    opacity: 1;
    border: 0px;
    margin: 0px;
    padding: 0px;
    position: relative;
    /* max-width: 100%; */

}

.span2s {
    box-sizing: border-box;
    display: block;
    width: initial;
    height: initial;
    background: none;
    opacity: 1;
    border: 0px;
    margin: 0px;
    padding: 0px;
    max-width: 100%;
}

.Images {
    border-radius: 10px;
    object-fit: cover;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 100%;
    height: 128px;
    /* width: 95px; */
    /* width: 139px; */


}

.css-1dm2hj8s {

    text-align: center;
}

.Inda {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.selected-button {
    background-color: rgb(255 255 255) !important;

    color: rgb(254, 44, 84) !important;
}

.Timer {
    padding: 5px;
    border: 4px solid white;
    border-radius: 51%;
    height: 50px;
    width: 50px;

}

.container {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.container p {
    margin: 5px;
    padding: 0px;
    color: rgb(17 69 60);
    font-weight: 700;
    font-size: 19px;
}

.NEWBOX {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -20px;

}

.Reimge {
    height: 100px;
    width: 100px;
}

.REbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.NewMark {
    color: black;
    font-size: 19px !important;
    margin: 0px;
    border-radius: 30%;
    padding: 3px;
}



.QuizBoxmian {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
   
    align-items: center;

}
.QuizBoxmiansecondThird{
    height: 355px;
    width: 200px;
    margin-left: 15px;
    margin-bottom: 10px;
    display: flex;
    margin-top: 5px;
    /* flex-wrap: wrap; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 20px;
}
.QuizBoxmiansecond {
    margin-left: 15px;
    margin-bottom: 10px;
    display: flex;
    margin-top: 5px;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 20px;
}

.QuizBoxmiansecond img {
    height: auto;
    object-fit: contain;
    width: 320px;
    border-radius:20px
}

.New {
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    
}
.Shaosw{
    box-shadow: 10px 11px 34px -15px rgba(103,201,12,0.62);

}



.New p{
    color: #D269E6;
    line-height: 1.33;
    font-size: 26px;
    margin: 20px 0px;
    font-weight: 800;
}
.New img {
    height: auto;
    object-fit: contain;
    width: 366px;
    border-radius:20px
}
.Questionsection {
    margin-top: 75px;
}
.QuizBoxmiansecond h2 {
   
    color: black;
    font-weight: 500;
    font-size: 18px;
    padding: 10px;
    text-align: center;
    margin: 0px;

}
.classMainBox{
    width: 100%;
    /* overflow: scroll;
    display: flex; */
     
}
.classMainBox:hover {
    overflow-y: auto; /* Show vertical scrollbar */
}

/* Optional: Customize scrollbar appearance */
.classMainBox::-webkit-scrollbar {
    height: 4px;
    width: 3px; /* Width of the scrollbar */
}

.classMainBox::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Round the corners of the scrollbar thumb */
}

.classMainBox::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color of the scrollbar thumb on hover */
}

.Questionsection h2{
text-align: center;
}

.QuizBoxmiansecond p {
    text-align: center;

}
.Truans {
    margin-top: 20px;
    padding: 5px ;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    text-align: center;
    background-color: rgb(84, 255, 84);
}
/* box-shadow: red 0px 0px 5px; */

.Questionsection button{
    width: 100%;
    padding: 15px;
    font-size: 20px;
  
    color: black;
 margin-top: 5px;
 border-radius: 20px;
    border: 2px solid  #D269E6;
}
.navboxshood {
    box-shadow: rgba(89, 0, 217, 0.21) 0px 7px 11px 0px !important;

}

.Link{
text-decoration: none;
}

.QuizBoxmiansecond span {
    font-size: 17px;
    text-align: center;
    margin-right: 10px;
    color: rgb(0, 183, 0);

}
.css-1q1iwm9 {
    margin :10px 0px 4px 0px;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    background: rgb(255 92 123);
    ;
    color: white;
    border: 1px solid transparent;
    border-radius: 10px;
    padding: 9px;
    width: 200px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}
.css-1q1iwm8 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    background: rgb(255 92 123);
    ;
    color: white;
    border: 1px solid transparent;
    border-radius: 10px;
    padding: 15px;
    width: 200px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}